import {formatTimeTo} from "../../shared/cards";
import {formatDateTimes} from "../../shared/time-formating";
import {addListener} from "../../shared/interaction-listener";

if (document.querySelector("#trailers-view")){
    addListener(function (){
        import(/* webpackChunkName: "trl-trv" */ './trailer-viewer')
            .then((module) => {
                module.initApp();
            })
    })
}

formatTimeTo();
formatDateTimes();
