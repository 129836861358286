function formatTimeTo(){
    const nowDt = new Date();
    const templateAirTag = document.getElementById("tag-on-air");

    document.querySelectorAll<HTMLElement>('.time-to-event').forEach((el: HTMLElement) => {
        if (!el.dataset["airStart"] || !el.dataset["airEnd"]){
            return;
        }
        const airStart = new Date(el.dataset["airStart"]);
        const airEnd = new Date(el.dataset["airEnd"]);

        if (airStart < nowDt && airEnd > nowDt && templateAirTag){
            el.innerHTML = templateAirTag.innerHTML;
            el.classList.remove("hidden");
        }
    })
}

export {formatTimeTo}
