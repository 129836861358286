import dayjs from "dayjs";
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime)

function formatDateTimes(){
    document.querySelectorAll<HTMLElement>('time[datetime]').forEach((el: HTMLElement) => {
        el.innerHTML = dayjs(el.getAttribute("datetime")).format(
            el.dataset.format || '');

    })
}

function formatTimeTo(){
    const nowDt = new Date();
    const next24h = new Date(nowDt.getTime() + 24*60*60*1000);
    const templateAirTag = document.getElementById("tag-on-air");
    const templateAirTagStatic = document.querySelector<HTMLTemplateElement>("#tag-on-air-top");

    document.querySelectorAll<HTMLElement>('.time-to-event').forEach((el: HTMLElement) => {
        if (!el.dataset["airStart"]){
            console.log(el.dataset)
            return;
        }
        const airStart = new Date(el.dataset["airStart"]);

        if (airStart > nowDt && airStart < next24h){
            el.textContent = dayjs(airStart).fromNow();
            el.classList.remove("hidden");
        }
        if (!el.dataset["airEnd"]){
            return;
        }
        const airEnd = new Date(el.dataset["airEnd"]);

        if (airStart < nowDt && airEnd > nowDt && templateAirTag && templateAirTagStatic){
            el.innerHTML = templateAirTag.innerHTML;
            el.classList.remove("hidden");

            const overlay = document.querySelector(`.overlays[data-vvid="${el.dataset['vvid']}"]`);
            if (overlay){
                overlay.append(templateAirTagStatic.content.cloneNode(true));
            }
        }
    })
}

export {formatTimeTo, formatDateTimes}