const _listeners: Array<() => void> = [];
let _triggered = false;

export function addListener(func: () => void): void {
    _listeners.push(func);
}

export function removeListener(func: () => void): void {
    if (_triggered) {
        return;
    }
    const foundIndex: number = _listeners.indexOf(func);

    if (foundIndex > -1) {
        _listeners.splice(foundIndex, 1);
    }
}

function _onTrigger(): void {
    if (_triggered) {
        return;
    }
    _triggered = true;

    for (const listener of _listeners) { // run all the user-defined listeners
        listener();
    }

    // Remove the listeners
    document.removeEventListener('mousedown', _onTrigger);
    document.removeEventListener('mousemove', _onTrigger);
    document.removeEventListener('touchstart', _onTrigger);
    document.removeEventListener('scroll', _onTrigger);
    document.removeEventListener('keydown', _onTrigger);

    // Finally, release local listeners
    while (_listeners.length) {
        _listeners.pop()
    }
}

if (typeof window !== 'undefined') {

    // Trigger our main function on any input from the user in the browser
    document.addEventListener('mousedown', _onTrigger);
    document.addEventListener('mousemove', _onTrigger);
    document.addEventListener('touchstart', _onTrigger);
    document.addEventListener('scroll', _onTrigger);
    document.addEventListener('keydown', _onTrigger);
}

export default {
    addListener,
    removeListener
};